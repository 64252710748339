// src/contexts/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {
  doc,
  setDoc,
  onSnapshot,
  updateDoc,
  arrayUnion,
  collection,
  getDocs,
  query,
  where
} from 'firebase/firestore';
import { toast } from 'react-toastify';

export const UserContext = createContext();

function fixKindergartenLevel(levelString) {
  if (!levelString.startsWith('level_k')) return levelString;
  const afterPrefix = levelString.slice('level_k'.length);
  if (afterPrefix.startsWith('_')) return levelString;
  return 'level_k_' + afterPrefix;
}

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading]   = useState(true);
  const [error, setError]       = useState(null);

  useEffect(() => {
    let unsubscribeUser = () => {};

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userRef = doc(db, 'users', user.uid);
          unsubscribeUser = onSnapshot(
            userRef,
            async (docSnap) => {
              if (docSnap.exists()) {
                const data = docSnap.data();
                let currentLevelId = data.currentLevel || 'level_1_1';

                // Fix kindergarten levels if needed
                const fixed = fixKindergartenLevel(currentLevelId);
                if (fixed !== currentLevelId) {
                  try {
                    await updateDoc(userRef, { currentLevel: fixed });
                    currentLevelId = fixed;
                  } catch (err) {
                    console.error('Error fixing kindergarten level:', err);
                  }
                }

                // If user doesn't have accountType, default to 'individual'
                const accountType = data.accountType || 'individual';

                // Check if user has no schoolId but was "invited" via email
                let schoolId = data.schoolId || null;
                let schoolName = data.schoolName || '';

                if (!schoolId) {
                  // Attempt to find an invitation in the schools subcollections
                  const matched = await findSchoolByEmail(user.email);
                  if (matched) {
                    schoolId = matched.schoolId;
                    schoolName = matched.schoolName;
                    try {
                      await updateDoc(userRef, {
                        schoolId,
                        schoolName
                      });
                    } catch (updateErr) {
                      console.error('Error updating user with schoolId:', updateErr);
                    }
                  }
                }

                setUserData({
                  uid: user.uid,
                  email: user.email,
                  isSchool: accountType === 'school',
                  accountType,
                  ...data,
                  schoolId,
                  schoolName,
                  completedSkillsByGradeLevel: data.completedSkillsByGradeLevel || {},
                  currentLevel: currentLevelId,
                });
              } else {
                // Create an initial user doc if none exists
                const initialData = {
                  email: user.email,
                  gradeLevel: '',
                  className: '',
                  avatar: '',
                  currentLevel: 'level_1_1',
                  currentFocusArea: 'focusArea_focusedBreathing',
                  totalMindfulnessMinutes: 0,
                  completedSkillsByGradeLevel: {},
                  achievements: [],
                  earnedBadges: [],
                  skillCompletionHistory: [],
                  streak: 0,
                  streakHistory: [],
                  theme: 'light',
                  hasCompletedOnboarding: false,
                  accountType: 'individual', // default
                };

                await setDoc(userRef, initialData, { merge: true });

                setUserData({
                  uid: user.uid,
                  email: user.email,
                  isSchool: false,
                  accountType: 'individual',
                  ...initialData,
                });
              }
              setLoading(false);
            },
            (error) => {
              console.error('Error fetching user document:', error);
              setError('Failed to load user data. Please try again later.');
              setLoading(false);
            }
          );
        } catch (error) {
          console.error('Error fetching user data:', error);
          setError('Failed to load user data. Please try again later.');
          setUserData(null);
          setLoading(false);
        }
      } else {
        // user logged out
        setUserData(null);
        setLoading(false);
      }
    });

    return () => {
      unsubscribeAuth();
      unsubscribeUser();
    };
  }, []);

  /**
   * Attempts to find any school doc for which there's a subcollection
   * teachers that has a doc with { email: userEmail }.
   * Returns { schoolId, schoolName } if found, else null.
   */
  async function findSchoolByEmail(userEmail) {
    try {
      const schoolsSnap = await getDocs(collection(db, 'schools'));
      for (const schoolDoc of schoolsSnap.docs) {
        const sData = schoolDoc.data();
        const teachersRef = collection(db, 'schools', schoolDoc.id, 'teachers');
        const teachersQ = query(teachersRef, where('email', '==', userEmail));
        const teachersSnap = await getDocs(teachersQ);

        if (!teachersSnap.empty) {
          // Found a match
          return {
            schoolId: schoolDoc.id,
            schoolName: sData.schoolName || '',
          };
        }
      }
      return null;
    } catch (err) {
      console.error('Error in findSchoolByEmail:', err);
      return null;
    }
  }

  // Example function for skill completion
  const markSkillAsComplete = async (
    skillId,
    gradeLevel,
    levelId,
    focusAreaId,
    skillDuration = 5
  ) => {
    if (!userData) {
      toast.error('User not logged in.');
      return;
    }
    try {
      const userRef = doc(db, 'users', userData.uid);
      const path = `completedSkillsByGradeLevel.${gradeLevel}.${levelId}.${focusAreaId}`;

      await updateDoc(userRef, {
        [path]: arrayUnion(skillId),
        totalMindfulnessMinutes: (userData.totalMindfulnessMinutes || 0) + skillDuration,
        skillCompletionHistory: arrayUnion({
          date: new Date().toISOString().split('T')[0],
          skillsCompleted: 1,
          timestamp: Date.now(),
          skillId,
        }),
      });

      toast.success('Skill marked as complete!');
    } catch (err) {
      console.error('Error marking skill as complete:', err);
      toast.error('Failed to mark skill as complete.');
      throw err;
    }
  };

  return (
    <UserContext.Provider
      value={{
        userData,
        loading,
        error,
        markSkillAsComplete,
        setUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
