// src/components/SkillsList/SkillsList.js

import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { UserContext } from '../../contexts/UserContext';
import './SkillsList.css';
import { toast } from 'react-toastify';

const SkillsList = () => {
  const { gradeLevel: paramGradeLevel, levelId: paramLevelId, focusArea: paramFocusArea } = useParams();
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  const [skills, setSkills] = useState([]);
  const [completedSkills, setCompletedSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSkills = useCallback(async () => {
    if (!userData) {
      setError('User data not available.');
      setLoading(false);
      return;
    }

    if (!paramFocusArea || !paramGradeLevel || !paramLevelId) {
      setError('Insufficient parameters to load skills.');
      setLoading(false);
      return;
    }

    try {
      // Query skills collection by grade, level, and focus area
      const skillsRef = collection(db, 'skills');
      const q = query(
        skillsRef,
        where('gradeId', '==', paramGradeLevel),
        where('levelId', '==', paramLevelId),
        where('focusAreaId', '==', paramFocusArea)
      );

      const snapshot = await getDocs(q);
      if (snapshot.empty) {
        setError('No skills found for this level and focus area.');
        setLoading(false);
        return;
      }

      const fetchedSkills = snapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
      }));

      setSkills(fetchedSkills);

      // Compute completed skills from userData
      const gradeData = userData.completedSkillsByGrade || {};
      const gradeObj = gradeData[paramGradeLevel] || {};
      const completedForFocus = gradeObj[paramFocusArea] || [];
      if (Array.isArray(completedForFocus)) {
        setCompletedSkills(completedForFocus);
      } else {
        setCompletedSkills([]);
      }

      setLoading(false);
    } catch (err) {
      console.error('Error fetching skills:', err);
      setError('Failed to load skills. Please try again later.');
      setLoading(false);
    }
  }, [userData, paramFocusArea, paramGradeLevel, paramLevelId]);

  useEffect(() => {
    fetchSkills();
  }, [fetchSkills]);

  const handleSkillClick = (skill) => {
    // Navigate to skill detail page
    navigate(
      `/grades/${encodeURIComponent(paramGradeLevel)}/levels/${encodeURIComponent(
        paramLevelId
      )}/skills/${encodeURIComponent(paramFocusArea)}/${encodeURIComponent(skill.id)}`
    );
  };

  if (loading) return <p>Loading skills...</p>;
  if (error) return <p>{error}</p>;

  if (skills.length === 0) {
    return (
      <div className="no-skills-container">
        <h2>Skills for {paramFocusArea}</h2>
        <p>No skills found for this focus area.</p>
        <button onClick={() => navigate(-1)} className="back-button">
          Go Back
        </button>
      </div>
    );
  }

  return (
    <div className="skills-list-container">
      <h2>Skills for {paramFocusArea}</h2>
      <ul className="skills-list">
        {skills.map((skill) => (
          <li
            key={skill.id}
            className={`skill-item ${
              completedSkills.includes(skill.id) ? 'completed' : 'incomplete'
            }`}
          >
            <div className="skill-info">
              <span className="skill-name">{skill.name || 'Unnamed Skill'}</span>
              <span className="skill-status">
                {completedSkills.includes(skill.id) ? '✅ Completed' : '❌ Incomplete'}
              </span>
            </div>
            <button onClick={() => handleSkillClick(skill)} className="view-details-button">
              View Details
            </button>
          </li>
        ))}
      </ul>
      <button onClick={() => navigate(-1)} className="back-button">
        Go Back
      </button>
    </div>
  );
};

export default SkillsList;
