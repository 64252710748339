// src/App.js

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/styles.css';
import { UserProvider, UserContext } from './contexts/UserContext';
import ErrorBoundary from './components/Home/sections/ErrorBoundary';
import SkillsList from './components/SkillsList/SkillsList';

// Lazy loaded components
const Navbar = lazy(() => import('./components/Navbar/Navbar'));
const CookieConsent = lazy(() => import('./components/CookieConsent/CookieConsent'));

const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const Home = lazy(() => import('./components/Home/Home'));
const Login = lazy(() => import('./components/Login/Login'));
const SignUp = lazy(() => import('./components/SignUp/SignUp'));
const ResetPassword = lazy(() => import('./components/ResetPassword/ResetPassword'));
const MeditationCard = lazy(() => import('./components/MeditationCard/MeditationCard'));
const LevelsOverview = lazy(() => import('./components/LevelsOverview/LevelsOverview'));
const LevelDetail = lazy(() => import('./components/LevelDetail/LevelDetail'));
const ChallengeDetail = lazy(() => import('./components/ChallengeDetail/ChallengeDetail'));
const BadgesPage = lazy(() => import('./components/BadgesPage/BadgesPage'));
const PreferencesPage = lazy(() => import('./components/PreferencesPage/PreferencesPage'));
const UserHome = lazy(() => import('./components/UserHome/UserHome'));
const FreePreview = lazy(() => import('./components/FreePreview/FreePreview'));
const AddInitialData = lazy(() => import('./components/AddInitialData/AddInitialData'));
const GrantAdmin = lazy(() => import('./components/GrantAdmin/GrantAdmin'));
const NotFound = lazy(() => import('./components/NotFound/NotFound'));
const AdminRoleAssigner = lazy(() => import('./components/AdminRoleAssigner/AdminRoleAssigner'));
const FocusAreaDetail = lazy(() => import('./components/FocusAreaDetail/FocusAreaDetail'));
const SkillDetail = lazy(() => import('./components/SkillDetail/SkillDetail'));
const FiveMinPrep = lazy(() => import('./components/Home/offers/FiveMinPrep'));
const ResearchBacked = lazy(() => import('./components/Home/offers/ResearchBacked'));
const SchoolHome = lazy(() => import('./components/Home/offers/SchoolHome'));
const BlogPostPage = lazy(() => import('./components/Home/BlogPostPage/BlogPostPage'));

// School Mode Pages
const AdminSetupPage     = lazy(() => import('./components/schoolMode/AdminSetupPage'));
const AddUsersPage       = lazy(() => import('./components/schoolMode/AddUsersPage'));
const ManageSchoolUsers  = lazy(() => import('./components/schoolMode/ManageSchoolUsers'));
const SchoolDashboard    = lazy(() => import('./components/schoolMode/SchoolDashboard'));
const SchoolPortal       = lazy(() => import('./components/schoolMode/SchoolPortal'));

// Temporary placeholder for future school signup flow
const SchoolSignupPage = () => (
  <div style={{ margin: '2rem' }}>
    <h1>School Plan Signup (Placeholder)</h1>
    <p>
      This is where you’d integrate a payment flow for the school plan. 
      For now, you can manually set <code>accountType: "school"</code> 
      in Firestore for testing.
    </p>
  </div>
);

// LEGAL PAGES
const LegalCenter          = lazy(() => import('./components/LegalCenter/LegalCenter'));
const TermsOfService       = lazy(() => import('./components/LegalCenter/TermsOfService'));
const PrivacyPolicy        = lazy(() => import('./components/LegalCenter/PrivacyPolicy'));
const CookiePolicy         = lazy(() => import('./components/LegalCenter/CookiePolicy'));
const RefundPolicy         = lazy(() => import('./components/LegalCenter/RefundPolicy'));
const DMCAPolicy           = lazy(() => import('./components/LegalCenter/DMCAPolicy'));
const GeneralDisclaimer    = lazy(() => import('./components/LegalCenter/GeneralDisclaimer'));
const ShippingPolicy       = lazy(() => import('./components/LegalCenter/ShippingPolicy'));
const AffiliateDisclosure  = lazy(() => import('./components/LegalCenter/AffiliateDisclosure'));
const AccessibilityStatement = lazy(() => import('./components/LegalCenter/AccessibilityStatement'));
const WorkshopPolicy       = lazy(() => import('./components/LegalCenter/WorkshopPolicy'));

// Must be logged in to see protected routes
function ProtectedRoute({ children }) {
  const { userData, loading } = React.useContext(UserContext);

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }
  return userData ? children : <Navigate to="/login" />;
}

// Must have accountType: "school" (i.e., isSchool = true)
function SchoolRoute({ children }) {
  const { userData, loading } = React.useContext(UserContext);

  if (loading) {
    return (
      <div className="loading-container">
        <p>Loading...</p>
      </div>
    );
  }
  // If user isSchool => allow; otherwise, redirect
  return userData && userData.isSchool ? children : <Navigate to="/dashboard" />;
}

function App() {
  return (
    <Router>
      <UserProvider>
        <ErrorBoundary>
          <Suspense fallback={<div className="loading-container"><p>Loading Navbar...</p></div>}>
            <Navbar />
          </Suspense>

          {/* GDPR-Style Cookie Consent */}
          <Suspense fallback={<div className="loading-container"><p>Loading Cookie Banner...</p></div>}>
            <CookieConsent />
          </Suspense>

          <ToastContainer />

          <Suspense fallback={<div className="loading-container"><p>Loading Page...</p></div>}>
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/free-preview" element={<FreePreview />} />

              {/* Offer Subpages (Public) */}
              <Route path="/offer/5-min-zero-prep" element={<FiveMinPrep />} />
              <Route path="/offer/research-backed" element={<ResearchBacked />} />
              <Route path="/offer/school-home-friendly" element={<SchoolHome />} />

              {/* Blog Page */}
              <Route path="/blog/:id" element={<BlogPostPage />} />

              {/* TEMP: School signup placeholder */}
              <Route path="/school-signup" element={<SchoolSignupPage />} />

              {/* LEGAL ROUTES */}
              <Route path="/legal-center" element={<LegalCenter />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/cookie-policy" element={<CookiePolicy />} />
              <Route path="/refund-policy" element={<RefundPolicy />} />
              <Route path="/dmca-policy" element={<DMCAPolicy />} />
              <Route path="/general-disclaimer" element={<GeneralDisclaimer />} />
              <Route path="/shipping-policy" element={<ShippingPolicy />} />
              <Route path="/affiliate-disclosure" element={<AffiliateDisclosure />} />
              <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
              <Route path="/workshop-policy" element={<WorkshopPolicy />} />

              {/* Protected Routes */}
              <Route
                path="/preferences"
                element={
                  <ProtectedRoute>
                    <PreferencesPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/meditation"
                element={
                  <ProtectedRoute>
                    <MeditationCard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/levels"
                element={
                  <ProtectedRoute>
                    <LevelsOverview />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/grades/:gradeLevel/levels/:levelId"
                element={
                  <ProtectedRoute>
                    <LevelDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/grades/:gradeLevel/levels/:levelId/focusAreas/:focusArea"
                element={
                  <ProtectedRoute>
                    <SkillsList />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/challenges/:gradeLevel/levels/:levelId/:challengeId"
                element={
                  <ProtectedRoute>
                    <ChallengeDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/badges"
                element={
                  <ProtectedRoute>
                    <BadgesPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/user-home"
                element={
                  <ProtectedRoute>
                    <UserHome />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/school-portal"
                element={
                  <SchoolRoute>
                    <SchoolPortal />
                  </SchoolRoute>
                }
              />

              {/* School-Only Routes */}
              <Route
                path="/admin-setup"
                element={
                  <SchoolRoute>
                    <AdminSetupPage />
                  </SchoolRoute>
                }
              />
              <Route
                path="/school-add-users"
                element={
                  <SchoolRoute>
                    <AddUsersPage />
                  </SchoolRoute>
                }
              />
              <Route
                path="/school-manage-users"
                element={
                  <SchoolRoute>
                    <ManageSchoolUsers />
                  </SchoolRoute>
                }
              />
              <Route
                path="/school-dashboard"
                element={
                  <SchoolRoute>
                    <SchoolDashboard />
                  </SchoolRoute>
                }
              />

              {/* Admin-Only (or still School-Only?) */}
              <Route
                path="/admin-dashboard"
                element={
                  <SchoolRoute>
                    <AdminRoleAssigner />
                  </SchoolRoute>
                }
              />
              <Route
                path="/add-initial-data"
                element={
                  <SchoolRoute>
                    <AddInitialData />
                  </SchoolRoute>
                }
              />
              <Route
                path="/grant-admin"
                element={
                  <SchoolRoute>
                    <GrantAdmin />
                  </SchoolRoute>
                }
              />

              {/* Optional Focus Area Detail */}
              <Route
                path="/focus-areas/:focusArea"
                element={
                  <ProtectedRoute>
                    <FocusAreaDetail />
                  </ProtectedRoute>
                }
              />

              {/* Skill Detail */}
              <Route
                path="/grades/:gradeLevel/levels/:levelId/skills/:focusArea/:skillId"
                element={
                  <ProtectedRoute>
                    <SkillDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/skills/:skillId"
                element={
                  <ProtectedRoute>
                    <SkillDetail />
                  </ProtectedRoute>
                }
              />

              {/* 404 Not Found */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </UserProvider>
    </Router>
  );
}

export default App;
